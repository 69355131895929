import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Container, Image } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";
import axios from "axios";
import { formatNumber, formatNumberWithout } from "../helper";
import "../style.css";

function Produk() {
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);

  function like(e) {
    return e.target.classList.value === "fa-regular fa-heart like"
      ? (e.target.classList.value = "fa-solid fa-heart like text-danger")
      : (e.target.classList.value = "fa-regular fa-heart like");
  }

  const getCategory = async () => {
    try {
      const response = await axios(`${process.env.REACT_APP_API_URL}category`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      });
      if (response.data.status) {
        setProduct(response.data.data);
      } else {
        setProduct([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProduct = async (item) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}product/get/list?category=${item}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );
      if (response.data.status) {
        setCategory(response.data.data);
      } else {
        setCategory([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getWhatsApp = async (
    productName,
    minWeight,
    maxWeight,
    age_year,
    age_month
  ) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}${"wa-rotator"}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );

      const message = `Saya ingin menanyakan produk ${productName}, berat: ${minWeight}-${maxWeight} kg, usia: ${age_year} tahun, ${age_month} bulan.`;
      const whatsappURL = `https://wa.me/${
        response.data.data.phone
      }?text=${encodeURIComponent(message)}`;

      return window.open(whatsappURL, "_blank", "rel=noopener noreferrer");
    } catch (error) {
      console.error("Error fetching WhatsApp number:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const cardImgStyle = {
    height: "200px",
    objectFit: "cover",
  };

  const cardTextStyle = {
    fontSize: "14px",
  };

  const cardTitleStyle = {
    ...cardTextStyle,
    fontWeight: 500,
    color: "black",
    fontSize: "20px",
  };

  return (
    <div className="properties">
      <Container>
        <AnimationTitles className="title mx-auto" size="28px" weight="700">
          <motion.span style={{ color: "#e9c535", fontWeight: "Bold" }}>
            Pilih{" "}
          </motion.span>
          &nbsp;
          <span style={{ color: "#6b8b65", fontWeight: "Normal" }}>
            {" "}
            Qurbanmu!{" "}
          </span>
        </AnimationTitles>
        <p>
          Memilih hewan qurban pada Idul Adha bukan hanya soal praktis, tetapi
          juga nilai-nilai mendalam. Keputusan ini mencerminkan keterlibatan dan
          kepedulian pada sesama, serta pelaksanaan perintah agama.
        </p>
        <motion.div
          initial={{ x: -80 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.2 }}
        >
          <Swiper
            slidesPerView={4}
            spaceBetween={15}
            grabCursor={true}
            loop={true}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            breakpoints={{
              0: { slidesPerView: 2 },
              520: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              992: { slidesPerView: 4 },
              1198: { slidesPerView: 5 },
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper mt-4"
          >
            {product
              .filter((item) => item.type === "1")
              .map((item, index) => (
                <SwiperSlide key={index}>
                  <Card className="bg-black-100 rounded">
                    <Card.Body className="p-2">
                      <div className="rounded overflow-hidden position-relative">
                        <Card.Img
                          variant="top"
                          src={item.image}
                          style={cardImgStyle}
                        />
                        <i
                          className="fa-regular fa-heart like"
                          onClick={like}
                        ></i>
                      </div>
                      <br></br>
                      <h5
                        style={{
                          marginLeft: "15px",
                          textAlign: "center",
                          ...cardTitleStyle,
                        }}
                      >
                        {item.name}
                      </h5>
                      <h4 style={{ textAlign: "center", fontWeight: 600 }}>
                        {formatNumber(item.price)}
                      </h4>
                      <Button
                        className="w-100 mt-4"
                        style={{ backgroundColor: "#597e52", border: "0px" }}
                        type="submit"
                        onClick={() => getProduct(item.name)}
                      >
                        Lihat Produk
                      </Button>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              ))}
          </Swiper>
          <Row className="d-flex">
            {category
              ? category.map((item, index) => {
                  return (
                    <Col xs={6} md={3} key={index}>
                      <Card className="bg-black-100 rounded">
                        <Card.Body className="p-2">
                          <div className="rounded overflow-hidden position-relative text-center">
                            <Image
                              src={item.image}
                              style={{
                                width: "100%",
                                objectFit: "contai",
                              }}
                            />
                            <i
                              className="fa-regular fa-heart like"
                              onClick={like}
                            ></i>
                          </div>
                          <br></br>
                          <h5
                            style={{
                              marginLeft: "15px",
                              textAlign: "center",
                              ...cardTitleStyle,
                            }}
                          >
                            {item.name}
                          </h5>
                          <h4 style={{ textAlign: "center", fontWeight: 600 }}>
                            {formatNumber(item.price)}
                          </h4>
                          <div style={cardTextStyle}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ marginLeft: "15px" }}>
                                <div>Usia</div>
                                <div style={{ fontWeight: 500 }}>
                                  {item.age_year} Thn, {item.age_month} Bln
                                </div>
                              </div>
                              <div style={{ marginRight: "15px" }}>
                                <div>Berat</div>
                                <div style={{ fontWeight: 500 }}>
                                  {item.min_weight} - {item.max_weight} kg
                                </div>
                              </div>
                            </div>
                          </div>

                          <br></br>
                          <Button
                            className="w-100 mt-4"
                            style={{
                              backgroundColor: "#597e52",
                              border: "0px",
                            }}
                            type="submit"
                            onClick={() =>
                              getWhatsApp(
                                item.name,
                                item.min_weight,
                                item.max_weight,
                                item.age_year,
                                item.age_month
                              )
                            }
                          >
                            Tanya Produk
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })
              : ""}
          </Row>
        </motion.div>
        {/* <motion.div
          initial={{ x: -80 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.2 }}
        >
          <Swiper
            slidesPerView={5}
            spaceBetween={15}
            grabCursor={true}
            loop={true}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            breakpoints={{
              0: { slidesPerView: 2 },
              520: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              992: { slidesPerView: 4 },
              1198: { slidesPerView: 5 },
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper mt-4"
          >
            {product
              .filter((item) => item.type === "2")
              .map((item, index) => (
                <SwiperSlide key={index}>
                  <Card className="bg-black-100 rounded">
                    <Card.Body className="p-2">
                      <div className="rounded overflow-hidden position-relative">
                        <Card.Img
                          variant="top"
                          src={item.image}
                          style={cardImgStyle}
                        />
                        <i
                          className="fa-regular fa-heart like"
                          onClick={like}
                        ></i>
                      </div>
                      <br></br>
                      <h5
                        style={{
                          marginLeft: "15px",
                          textAlign: "left",
                          ...cardTitleStyle,
                        }}
                      >
                        {item.name}
                      </h5>
                      <div style={cardTextStyle}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ marginLeft: "15px" }}>
                            <div>Usia</div>
                            <div style={{ fontWeight: 500 }}>
                              {item.age_year} Thn, {item.age_month} Bln
                            </div>
                          </div>
                          <div style={{ marginRight: "15px" }}>
                            <div>Berat</div>
                            <div style={{ fontWeight: 500 }}>
                              {item.min_weight} - {item.max_weight} kg
                            </div>
                          </div>
                        </div>
                      </div>

                      <br></br>
                      <h4 style={{ textAlign: "center", fontWeight: 600 }}>
                        {formatNumber(item.price)}
                      </h4>
                      <Button
                        className="w-100 mt-4"
                        style={{ backgroundColor: "#597e52", border: "0px" }}
                        type="submit"
                        onClick={() =>
                          getWhatsApp(
                            item.name,
                            item.min_weight,
                            item.max_weight,
                            item.age_year,
                            item.age_month
                          )
                        }
                      >
                        Tanya Produk
                      </Button>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              ))}
          </Swiper>
        </motion.div> */}
      </Container>
    </div>
  );
}

export default Produk;
