import { BrowserRouter } from "react-router-dom";
import Paket from "./pages/Paket";
import Agency from "./pages/Agency";
import Footer from "./pages/Footer";
import Keunggulan from "./pages/Keunggulan";
import Header from "./pages/Header";
import Faq from "./pages/Faq";
import Tentang from "./pages/Tentang";
import Produk from "./pages/Produk";
import Hubungi from "./pages/Hubungi";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Keunggulan />
        <Produk />
        <Paket />
        <Agency />
        <Tentang />
        <Hubungi />
        <Faq />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
