import { Container, Accordion, Button } from "react-bootstrap";
import AnimationTitles from "../components/functions/AnimationTitles";
import axios from "axios";
import { useEffect, useState } from "react";

function Faq() {
  const [faq, setFaq] = useState([]);

  const getFaq = async () => {
    try {
      const response = await axios(`${process.env.REACT_APP_API_URL}${"faq"}`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      });

      setFaq(response.data.data);
    } catch (error) {
      console.error('Error fetching FAQ:', error);
    }
  };

  useEffect(() => {
    getFaq();
  }, []);

  const toggleAccordion = (index) => {
    const newFaq = [...faq];
    newFaq[index].isOpen = !newFaq[index].isOpen;
    setFaq(newFaq);
  };

  return (
    <div className="faq">
      <Container>
        <AnimationTitles className="title mx-auto" size="24px" weight="700">
          <span style={{ color: "#6b8b65" }}>
            {" "}
            Pertanyaan yang Sering Diajukan (FAQ)
          </span>
          &nbsp;
        </AnimationTitles>
        <Accordion>
          {faq.map((item, index) => (
            <Accordion.Item key={index} eventKey={index.toString()}>
              <Accordion.Header onClick={() => toggleAccordion(index)}>
                {item.question}
              </Accordion.Header>
              <Accordion.Body
                style={{ backgroundColor: "#f2f4f7" }}
                className={item.isOpen ? "show" : ""}
              >
                {item.answer}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
    </div>
  );
}

export default Faq;
