import { Button, Container, Col, Row, Image, Form } from "react-bootstrap";
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";
import axios from "axios";
import { useEffect, useState } from "react";

function Hubungi() {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const getWhatsApp = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}${"wa-rotator"}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );

      const whatsappMessage = `Saya, ${name} ingin bertanya: ${message}.`;
      const whatsappURL = `https://wa.me/${
        response.data.data.phone
      }?text=${encodeURIComponent(whatsappMessage)}`;

      window.open(whatsappURL, "_blank", "rel=noopener noreferrer");
    } catch (error) {
      console.error("Error fetching WhatsApp number:", error);
    }
  };

  return (
    <div className="hubungi">
      <Container className="">
        <motion.div
          initial={{ x: -200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.2 }}
        >
          <Row>
            <Col xl={6}>
              <Col className="contact">
                <h3>Tanya tentang program kami</h3>
                <p>
                  Kirimkan pesan kepada kami dan tim kami akan segera
                  menghubungi Anda
                </p>
                <Form.Control
                  type="text"
                  placeholder="Nama Lengkap"
                  id="name"
                  value={name}
                  onChange={handleNameChange}
                />
                {/* <br />
                <Form.Control
                  type="number"
                  id="phone"
                  maxLength={12}
                  placeholder="No. WhastApp"
                  
                /> */}
                <br />
                <Form.Control
                  type="text"
                  id="message"
                  maxLength={12}
                  placeholder="Pesan"
                  value={message}
                  onChange={handleMessageChange}
                />
                <br />
                <Button
                  className="w-100 buttonCon"
                  type="submit"
                  // onClick={() => getWhatsApp()}
                  onClick={getWhatsApp}
                >
                  Kirim
                </Button>
              </Col>
            </Col>
            <Col xl={5} className="align-self-center">
              <Col className="testContact">
                <p style={{ fontFamily: "'Barlow', sans-serif" }}>
                  <span style={{ fontSize: "20px", fontWeight: 700 }}>
                    Dengan YaTernak
                  </span>{" "}
                  saya juga makin semangat dalam memelihara sapi saya, karena
                  tau ada banyak orang yang peduli untuk ber-kurban dan saya
                  dipercaya sebagai mitra untuk menyediakan hewan kurban, saya
                  doakan semua sehat-sehat dan mendapat keberkahan. Amin
                </p>
              </Col>
              <Row className="d-flex justify-content-start">
                <Col xs={2}>
                  <Image
                    src={require("../images/properties/pexels-rachel-claire-8112843 1.webp")}
                    style={{
                      borderRadius: "100%",
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </Col>
                <Col xs={10}>
                  <h5 className="text-white" style={{ fontWeight: 500 }}>
                    Bapak Kodir
                  </h5>
                  <p
                    className="text-white"
                    style={{ marginTop: "-10px", fontWeight: 300 }}
                  >
                    Peternak
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </motion.div>
      </Container>
    </div>
  );
}

export default Hubungi;
